<template>
  <div>
    <FunnelBreadcrumb v-if="!hasMinimal" />

    <div :class="wrapperClass">
      <div class="flex h-full w-full flex-col justify-center p-24 md:w-[34rem]">
        <RevStepper
          v-if="hasMinimal"
          :active-step="STEP_NAMES.ADDRESS"
          :alternative-text-back="i18n(translations.previousPage)"
          :alternative-text-close="i18n(translations.stepperClose)"
          :alternative-text-completed="i18n(translations.stepperCompleted)"
          :alternative-text-current="i18n(translations.stepperCurrent)"
          :has-close="false"
          :hasBack="hasBackButton"
          :steps="steps"
          @back="goBack"
        />
        <div
          v-if="!hasMinimal"
          class="border-static-default-low flex justify-center border-b-2 py-16"
        >
          <span class="body-1">
            {{ i18n(translations.headerTitle) }}
          </span>
        </div>

        <div class="flex flex-col justify-start pt-32">
          <span class="body-1-bold">
            {{ i18n(translations.formTitle) }}
          </span>
          <span class="body-1 my-16">
            {{ i18n(translations.formDescription) }}
          </span>
          <FormGenerator
            activate-address-validation-error-logger
            :form-config="formConfig"
            form-id="address-form"
            :isLoading="isLoading"
            submit-button-id="adress-submit"
            :submit-button-label="i18n(translations.formSubmitButton)"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from '#imports'
import { ref } from 'vue'

import { postOrder } from '@backmarket/http-api/src/api-specs-buyback/customer/customer'
import { getBuyBackAddress } from '@backmarket/http-api/src/api-specs-buyback/customer/getAddress'
import { postBuyBackAddress } from '@backmarket/http-api/src/api-specs-buyback/customer/postAddress'
import { HttpApiError } from '@backmarket/http-api/src/utils/HttpApiError'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useDynamicAddressFieldValidators } from '@backmarket/nuxt-module-address/useDynamicAddressFieldValidators'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { tw } from '@backmarket/utils/string/tw'
import { getPhoneNumberInfos } from '@ds/components/InputPhone'
import { RevStepper } from '@ds/components/Stepper'
import { type CountryCode } from 'libphonenumber-js'
import { storeToRefs } from 'pinia'

import { useResaleStepper } from '~/scopes/buyback/composables/useResaleStepper'
import { useGenerateAddressForm } from '~/scopes/buyback/config/useGenerateAddressForm'
import {
  ERROR_TYPES,
  ROUTE_NAMES,
  STEP_NAMES,
} from '~/scopes/buyback/constants'
import { FUNNEL_ERROR_MESSAGE } from '~/scopes/buyback/pages/constants'
import { useBuyBackStore } from '~/scopes/buyback/store'
import { CMS } from '~/scopes/cms/routes-names'

import FormGenerator, {
  type FormValues,
  type InputBase,
} from '../components/FormGenerator/FormGenerator.vue'
import FunnelBreadcrumb from '../components/FunnelBreadcrumb/FunnelBreadcrumb.vue'

import translations from './Address.translations'

const route = useRoute()
const router = useRouter()
const buybackStore = useBuyBackStore()
const { offer, hasOffer } = storeToRefs(buybackStore)
if (!hasOffer.value) {
  router.push({
    name: CMS.BUYBACK,
    params: {
      pageName: 'home',
    },
  })
}

const { openErrorToast } = useTheToast()
const i18n = useI18n()
const { user } = useUserStore()
const tracking = useTracking()
const logger = useLogger()
const experiments = useExperiments()
const { getDynamicAddressFieldValidators } = useDynamicAddressFieldValidators()
const { steps, goBack, hasBackButton } = useResaleStepper({
  activeStepName: STEP_NAMES.ADDRESS,
  hasShippingStep: offer.value?.has_shipping_choices,
})

const hasMinimal = experiments['experiment.buybackHeader'] === 'withMinimal'
const isLoading = ref(false)
const addressPublicId = ref('')

const wrapperClass = hasMinimal
  ? tw`flex justify-center`
  : tw`bg-surface-default-low flex justify-center md:py-32`

const { data } = await useHttpFetch(getBuyBackAddress)

// If customer address is not existing the API resolves in 404
// in this case it will fail silently and user will have to fill in the address
const initialAddressFormConfig = useGenerateAddressForm(data.value)

const initialFormValidation = initialAddressFormConfig.reduce(
  (acc, input) => {
    return {
      ...acc,
      [input.id]: input.validation || [],
    }
  },
  {} as Record<string, InputBase['validation']>,
)

const addressValidation = await getDynamicAddressFieldValidators({
  featureFlag: 'FF_ENABLE_ADDRESS_FIELD_VALIDATION_TRADEIN',
  initialFormValues: initialFormValidation,
  fallbackValidators: initialFormValidation,
  metadata: {
    scope: 'customer',
  },
})

const formConfig = initialAddressFormConfig.map((input) => {
  return {
    ...input,
    validation:
      (addressValidation[
        input.id as keyof typeof addressValidation
      ] as InputBase['validation']) || input.validation,
  }
})

async function handleSubmit(values: FormValues) {
  isLoading.value = true
  tracking.trackClick({
    zone: 'buyback',
    name: 'personal_info',
    value: {
      category: route.params?.id,
    },
  })

  const { dial, nationalNumber } = getPhoneNumberInfos(
    values.phone as string,
    values.country as CountryCode,
  )
  const body = {
    ...values,
    firstName: user.firstName,
    lastName: user.lastName,
    countryDialInCode: dial,
    phone: nationalNumber,
  }

  try {
    const payload = await $httpFetch(postBuyBackAddress, {
      body,
    })

    addressPublicId.value = payload?.public_id
  } catch (errors) {
    logger.error(FUNNEL_ERROR_MESSAGE.POST_ADDRESS)

    let formattedErrors = ''
    Object.keys(body).forEach((key) => {
      const err = (errors as Record<string, Array<string>>)[key]
      if (err) {
        formattedErrors += `${err.join('\n')}\n`
      }
    })

    openErrorToast({
      content: formattedErrors,
    })
    isLoading.value = false

    return
  }

  if (offer.value?.has_shipping_choices && addressPublicId.value) {
    router.push({
      name: ROUTE_NAMES.SHIPPING,
      params: {
        ...route.params,
        addressId: addressPublicId.value,
      },
      query: route.query,
    })
  } else if (addressPublicId.value) {
    try {
      const payload = await $httpFetch(postOrder, {
        body: {
          listing_id: route.params?.listingId,
          customerAddressId: addressPublicId.value,
          shippingId: offer.value?.defaultShippingModeId,
        },
      })

      tracking.trackBuybackConfirmation({
        buybackType: 'bb_normal',
        buybackOrderId: payload.id,
        commission: payload.estimatedCommission?.amount,
        conversionValue: payload.price,
        conversionCurrency: payload.estimatedCommission?.currency,
      })

      router.push({
        name: ROUTE_NAMES.CONFIRMATION,
        params: {
          id: payload.id,
        },
        ...((route?.query?.partner && {
          query: { partner: route.query.partner },
        }) ||
          {}),
      })
    } catch (postOrderError) {
      isLoading.value = false
      if (postOrderError instanceof HttpApiError) {
        if (postOrderError.type === ERROR_TYPES.INVALID_ID_ERROR_TYPE) {
          openErrorToast({
            content: i18n(translations.formErrorUnableToProcess),
          })
        } else if (postOrderError.type === ERROR_TYPES.NOT_FOUND_ERROR_TYPE) {
          openErrorToast({
            content: i18n(translations.formErrorListingNotFound),
          })
        } else {
          openErrorToast({
            content: i18n(translations.formApiError),
          })
        }
      } else {
        openErrorToast({
          content: i18n(translations.formApiError),
        })
      }
    }
  }
}
</script>
